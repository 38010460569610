<template>
  <BaseSection
    :id="blok.anchor"
    v-editable="blok"
    data-animate="fadeIn"
    data-animate-visible="300"
    :blok="{ ...blok, blocks: [] }"
    :class="{
      'overflow-hidden': blok.image_layout?.includes('-cropped'),
      'cursor-pointer clickable': hasLink && blok.clickable_component,
    }"
    @click="onClick"
  >
    <div
      :class="{
        'container inside': !blok.full_width && blok.blocks_full_width,
      }"
    >
      <div :class="layoutCss" class="h-full relative">
        <div
          class="text-wrapper self-center reveal reveal-text"
          :class="colCss"
        >
          <div v-if="blok.pre_headline" class="pre-h2">
            {{ blok.pre_headline }}
          </div>
          <h2
            v-if="blok.headline"
            :class="blok.headline_class"
            class="gradient-heading"
          >
            {{ blok.headline }}
          </h2>
          <div
            v-if="text"
            :class="blok.content_class"
            class="hero-description"
            v-html="text"
          ></div>

          <template v-if="isBlocksPositionAfterText">
            <StoryblokComponent
              v-for="blok in blok.blocks"
              :key="blok._uid"
              :blok="blok"
            />
          </template>

          <slot name="links" />
          <MyLink
            v-if="hasLink && isLinkPositionAfterText"
            v-show="!isLinkHidden"
            v-bind="link.bind"
          >
            {{ linkLabel }}
          </MyLink>
        </div>
        <div
          v-if="hasImage || isLottie"
          class="image-wrapper relative reveal reveal-image min-w-[200px] mx-auto"
          :class="imageWrapperClasses"
        >
          <Asset :blok rounded :class="props.blok.image_classes" />
        </div>
      </div>

      <slot />
    </div>

    <template v-if="isBlocksPositionBottom">
      <div
        :class="{
          'container inside': blok.full_width && !blok.blocks_full_width,
        }"
      >
        <StoryblokComponent
          v-for="(itemBlok, i) in blok.blocks"
          :key="itemBlok._uid"
          v-bind="{ imageLayoutClasses }"
          :blok="{ ...itemBlok, ...utils.lottieProps(blok, true) }"
          :class="i === 0 ? classesBloksWrapper : null"
          class="foo reveal reveal-text"
        />
      </div>
    </template>

    <div v-if="hasLink && isLinkPositionBottom" :class="layoutCss">
      <MyLink v-bind="link.bind" rel="next">
        {{ linkLabel }}
      </MyLink>
    </div>

    <div v-if="blok.gradient === 'center'" class="follow-bg-wrap">
      <div class="follow-bg" style="height: 120%"></div>
    </div>
    <div v-else-if="blok.gradient === 'left'" class="home-blog-bg-wrap">
      <div class="home-blog-bg"></div>
    </div>
    <div v-else-if="blok.gradient === 'right'" class="cta-bg-wrap">
      <div class="cta-bg"></div>
    </div>
  </BaseSection>
</template>

<script setup>
const props = defineProps({ blok: Object });
const utils = useUtils();
const device = useDevice();

const isLottie = utils.isLottie(props.blok.image?.filename);
const linkUrl = computed(
  () => props.blok.link?.url || props.blok.link?.cached_url,
);
const hasLink = computed(() => !!linkUrl.value);
const linkLabel = computed(() =>
  props.blok.link_text.length ? props.blok.link_text : linkUrl.value,
);
const isLinkHidden = computed(() => !!props.blok.hidden_link);
const link = computed(() => {
  return {
    bind: {
      link: props.blok.link,
      linkText: props.blok.link_text,
      href: linkUrl.value,
      class: `${props.blok.background && props.blok.background_color !== "gray" ? "button-white" : ""}`,
      button: true,
    },
  };
});
const isLinkPositionAfterText = computed(
  () => hasLink && props.blok.link_position === "after-text",
);
const isLinkPositionBottom = computed(
  () =>
    hasLink &&
    (!props.blok.link_position || props.blok.link_position === "bottom"),
);

const onClick = () => {
  if (props.blok.clickable_component && hasLink) {
    utils.goto(utils.getUrlFromLink(props.blok.link));
  }
};

const isBlocksPositionAfterText = computed(
  () => props.blok.blocks_position === "after-text",
);
const isBlocksPositionBottom = computed(
  () => !props.blok.blocks_position || props.blok.blocks_position === "bottom",
);

const removeVideoBackground = () => {
  props.blok.video_background = [];
};

if (device.isFirefox || device.isMobile || !device.isApple) {
  removeVideoBackground();
}
const hasVideoBackground = computed(
  () => props.blok.video_background && props.blok.video_background.length === 1,
);

const text = computed(() => renderRichText(props.blok.content));

const center = computed(() => props.blok.layout === "center");
const hasImage = computed(
  () =>
    !hasVideoBackground.value && props.blok.image && props.blok.image.filename,
);
const layoutCss = computed(() => {
  return {
    "md:flex flex-wrap flex-row sm:space-x-[60px] h-full gap-x-[60px]":
      props.blok.layout === "left",
    "md:flex flex-wrap flex-row-reverse sm:space-x-[60px] sm:space-x-reverse h-full gap-x-[60px]":
      props.blok.layout === "right",
    "text-center": props.blok.layout === "center",
  };
});
const imageLayoutClasses = computed(() => {
  return {
    // 'default': props.blok.layout !== 'center' && (!props.blok.image_layout || props.blok.image_layout === 'default'),
    // 'full-size': props.blok.layout !== 'center' && props.blok.image_layout === 'full-size',
    overlapping: props.blok.image_layout?.startsWith("overlapping"),
    bottom:
      props.blok.layout === "center" || props.blok.image_layout === "bottom",
    "layout-center": props.blok.layout === "center",
    [props.blok.image_classes]: true,
    [props.blok.image_size]: true,
  };
});
const colCss = computed(() => {
  return {
    "flex-1 md:w-3/4 xl:max-w-[710px]":
      props.blok.layout === "left" || props.blok.layout === "right",
    "md:w-3/4 xl:max-w-[710px] mx-auto text-center":
      props.blok.layout === "center",
  };
});

const changeImagePosition =
  computed(
    () =>
      !props.blok.image_layout ||
      props.blok.image_layout === "default" ||
      props.blok.image_layout?.startsWith("overlapping"),
  ) && !props.blok.image_layout?.includes("center");
const imageWrapperClasses = computed(() => {
  return {
    ...colCss.value,
    "mt-10":
      isLottie &&
      props.blok.layout === "center" &&
      props.blok.image_layout === "center",
    // "mt-10 md:mt-0": props.blok.layout !== "center",
    "-mb-[40px]":
      hasImage.value && props.blok.padding_y === "sm" && changeImagePosition,
    "-mb-[50px] md:-mb-[100px]":
      hasImage.value &&
      (!props.blok.padding_y || props.blok.padding_y === "default") &&
      changeImagePosition,
    "-mb-[50px] md:-mb-[150px]":
      hasImage.value && props.blok.padding_y === "lg" && changeImagePosition,
    "-mb-[50px] md:-mb-[200px]":
      hasImage.value && props.blok.padding_y === "xl" && changeImagePosition,
    "align-start":
      hasImage.value &&
      (!props.blok.image_layout ||
        props.blok.image_layout === "default" ||
        !props.blok.image_layout?.includes("center")),
    "-mt-130":
      hasImage.value && props.blok.image_layout?.startsWith("overlapping"),
    "-mb-130":
      hasImage.value &&
      props.blok.image_layout?.startsWith("overlapping") &&
      props.blok.image_layout?.includes("center"),
    "-mb-100 -mt-100":
      props.blok.layout !== "center" && props.blok.image_layout === "full-size",
  };
});

const classesBloksWrapper = computed(() => {
  return {
    "mt-[20px]": props.blok.bloks_margin_top === "small",
    "mt-[40px]": props.blok.bloks_margin_top === "default",
    "mt-[80px]": props.blok.bloks_margin_top === "large",
  };
});
</script>

<style scoped>
@media (min-width: 768px) {
  .items-stretch .inside .image-wrapper {
    position: absolute;
    bottom: 0;
    left: 0%;
    right: 0%;
  }
  .zweikern_analytics_solution-card {
    min-height: 560px;
  }
}
</style>
<style lang="scss" scoped>
.text-center {
  .text-wrapper + .image-wrapper {
    margin-top: 40px;
  }
}
.clickable:hover {
  .reveal-image {
    transition: all 400ms ease;
    transform: scale(1.1);
  }

  &.section.gardient-primary {
    transition: all 2s ease;
  }

  &.section.bg-gray-gradient {
    transition: all 2s ease;
  }
}
.text-wrapper {
  position: relative;
  z-index: 1;
}
.image-wrapper {
  align-self: center;
  text-align: center;

  &.align-start {
    align-self: flex-start;
    text-align: center;
  }

  &.align-end {
    align-self: flex-end;
    text-align: center;
  }

  @media (min-width: 768px) {
    &.-mt-100 {
      margin-top: -100px;
    }

    &.-mt-130 {
      margin-top: -130px;
    }
    &.-mb-130 {
      margin-bottom: -130px;
    }
  }

  img {
    // max-width: 80%;
    width: 100%;
    &.medium {
      max-width: 80%;
    }

    &.small {
      max-width: 65%;

      &.overlapping {
        @media (min-width: 768px) {
        }
      }
    }
  }
}
img.bottom {
  position: relative;
  margin-top: 40px;

  &:not(.layout-center) {
    @media (min-width: 418px) {
      position: absolute;
      margin-top: -40px;
    }
  }
}
</style>

<style>
.image-wrapper .lottie {
  max-width: 70%;
  display: flex;
  align-items: center;
}
</style>
